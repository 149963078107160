import React, { useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerClusterer, useGoogleMap } from '@react-google-maps/api'
import styled from 'styled-components'
import SupplierIcon from '@assets/maps/supplier.png'
import BuyerIcon from '@assets/maps/buyer.png'
import FactoryIcon from '@assets/maps/factory.png'
import LogisticsIcon from '@assets/maps/logistics.png'
import { googleMapKey, roles } from '@constants'
import { getAllOrgLocations } from '@data'
import { Select } from '@base'
import { Spacings } from '@styles'

export const SelectWrapper = styled.div`
	margin: ${Spacings.SPACING_1B} 0 0 ${Spacings.SPACING_2B};
`

const MapControl = (props) => {
	const map = useGoogleMap()
	const ref = useRef()
	useEffect(() => {
		if (map && ref) {
			map.controls[window.google.maps.ControlPosition[props.position]].push(ref.current)
		}
	}, [map, ref])
	return <div ref={ref}>{props.children}</div>
}

const containerStyle = {
	width: '100%',
	height: '100%',
}

const center = {
	lat: 28.7041,
	lng: 77.1025,
}

const RoleType = [{ name: 'ALL' }, { name: roles.buyer }, { name: roles.fabricator }, { name: roles.logistic }, { name: roles.trader }]

const UserLocationMapping = () => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: googleMapKey,
	})
	const options = {
		imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
		maxZoom: 9,
	}

	const [activeMarker, setActiveMarker] = useState(null)
	const [selectedRole, setSelectedRole] = useState(RoleType[0])
	const [users, setUsers] = useState([])

	useEffect(() => {
		getAllOrgLocations()
			.then((res) => {
				const _resUser = res.filter((_item) => _item.latitude !== -1 && _item.longitude !== -1)
				setUsers(_resUser)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
			return
		}
		setActiveMarker(marker)
	}

	const markerIcon = (partnerType) => {
		switch (partnerType) {
			case roles.trader:
				return SupplierIcon
			case roles.buyer:
				return BuyerIcon
			case roles.fabricator:
			case roles.manufacturer:
				return FactoryIcon
			case roles.logistic:
				return LogisticsIcon
			default:
				break
		}
	}

	return isLoaded ? (
		<GoogleMap options={{ streetViewControl: false, mapTypeControl: false }} mapContainerStyle={containerStyle} center={center} zoom={5}>
			<MapControl position='LEFT_TOP'>
				<SelectWrapper>
					<Select
						id='select-role'
						placeholder='Select Role Type'
						data={RoleType}
						displayKey='name'
						primaryKey='name'
						value={selectedRole}
						onChange={setSelectedRole}
						label='Select Role'
					/>
				</SelectWrapper>
			</MapControl>

			<MarkerClusterer options={options}>
				{(clusterer) =>
					users
						.filter((_item) => _item.role === selectedRole?.name || selectedRole?.name === 'ALL')
						.map(({ mobile_number, full_name, role, latitude, longitude }, index) => (
							<Marker
								key={'loc_' + index}
								icon={markerIcon(role)}
								position={{ lat: latitude, lng: longitude }}
								onMouseOver={() => handleActiveMarker(index)}
								onMouseOut={() => handleActiveMarker(null)}
								clusterer={clusterer}
							>
								{activeMarker === index ? (
									<InfoWindow onCloseClick={() => setActiveMarker(null)}>
										<div>
											<span>
												{full_name} ({mobile_number})
											</span>
										</div>
									</InfoWindow>
								) : null}
							</Marker>
						))
				}
			</MarkerClusterer>
		</GoogleMap>
	) : null
}

export default UserLocationMapping
