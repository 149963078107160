import { useEffect, useRef, useState } from 'react'
import Button from './Button'
import { FloatingButton } from '@base'
import styled from 'styled-components'
import { Spacings, Text } from '@styles'
import { useDispatch } from 'react-redux'
import { toggleFileViewer } from '@data/state/action/root'

const IconWrapper = styled.div`
	margin-right: ${Spacings.SPACING_1B};

	& > svg {
		font-size: ${Text.X_LARGE};
	}
`

const UploadButton = ({ isFloating, label, onChange, accept = 'image', preview = true, ...props }) => {
	const dispatch = useDispatch()
	const inputRef = useRef()
	const [files, setFiles] = useState()

	const acceptType = {
		csv: '.csv',
		excel: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
		image: 'image/*, application/pdf',
		pdf: 'application/pdf',
		all: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*, application/pdf ',
	}

	const clickHandler = () => {
		if (inputRef.current && !props.disabled) {
			inputRef.current.click()
		}
	}

	const onFilesChange = (e) => {
		const files = e.target.files
		setFiles(files)
	}

	const renderInput = () => (
		<input
			ref={(_ref) => (inputRef.current = _ref)}
			accept={acceptType[accept]}
			style={{ display: 'none' }}
			type='file'
			onChange={onFilesChange}
			onClick={(e) => {
				e.target.value = ''
			}}
			multiple={props.multiple}
		/>
	)

	const renderButton = () => {
		if (isFloating) {
			return (
				<FloatingButton {...props} onClick={clickHandler}>
					{renderInput()}
				</FloatingButton>
			)
		}

		return (
			<Button {...props} onClick={clickHandler}>
				{props.icon && <IconWrapper>{props.icon}</IconWrapper>}
				{renderInput()}
				{label}
			</Button>
		)
	}

	const close = () => {
		setFiles(null)
	}

	const confirm = () => {
		close()
		if (props.multiple) {
			onChange(files)
			return
		}
		onChange(files[0])
	}

	useEffect(() => {
		if (!!files) {
			dispatch(toggleFileViewer(true, { files, onClose: close, onConfirm: confirm, local: true, confirmationText: 'Upload' }))
		}
		return () => {
			dispatch(toggleFileViewer(false))
		}
	}, [files])

	return renderButton()
}

export default UploadButton
