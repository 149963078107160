import React, { useEffect, useRef, useState } from 'react'
import { FieldWrapper, InputText, Label, StyledInput, MultiSelecteInputWrapper } from './styles'
import { Close, ExpandMore, ExpandLess, Check, Cancel } from '@material-ui/icons'

import styled, { css } from 'styled-components'
import { Spacings, Text } from '@styles'
import { debounce } from '@utils'
import Checkbox from '../Checkbox'

const SelectStyledInput = styled(StyledInput)`
	padding: 0 ${Spacings.SPACING_9B} 0 ${Spacings.SPACING_3B};
`

const OptionsList = styled.div`
	background: ${({ theme }) => theme.palette.background.white};
	border: 1px solid ${({ theme }) => theme.palette.background.border};
	border-radius: ${Spacings.SPACING_2B};
	position: absolute;
	width: 100%;

	top: calc(100% - 0.5rem);
	left: 0;
	z-index: 2;

	transition: all 0.15s ease-in-out;
	opacity: ${({ show }) => (show ? 1 : 0)};
	visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
	max-height: ${({ show }) => (show ? '18rem' : '0')};

	z-index: 2;
	overflow: scroll;
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`

const Option = styled.div`
	width: 100%;
	max-width: 100%;
	min-height: 2.25rem;
	display: flex;
	align-items: center;
	padding: 0 ${Spacings.SPACING_3B};
	font-size: ${Text.MEDIUM};
	cursor: pointer;
	color: ${({ theme }) => theme.palette.text.black};

	transition: background 0.15s ease-in-out;

	&:hover {
		background: ${({ theme }) => theme.palette.background.blueLightest};
	}

	${({ selected }) => {
		if (selected) {
			return css`
				color: ${({ theme }) => theme.palette.text.darkblack};
				background: ${({ theme }) => theme.palette.background.greenLightest};
				padding-right: ${Spacings.SPACING_10B};
				position: relative;
			`
		}
	}}

	${({ createItemOption }) => {
		if (createItemOption) {
			return css`
				padding: 0;
			`
		}
	}}

	& >span {
		display: inline-block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		pointer-events: none;
	}
`

const NoOption = styled(Option)`
	pointer-events: none;
	user-select: none;
`

const IconWrapper = styled.div`
	position: absolute;
	top: ${Spacings.SPACING_4B};
	right: ${({ right }) => right || Spacings.SPACING_2B};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	padding: 0.125rem;
	background: ${({ theme }) => theme.palette.background.white};
	color: ${({ theme }) => theme.palette.text.black};

	transition: all 0.15s ease-out;

	& > svg {
		font-size: ${Text.M_LARGE};
	}

	&:hover {
		background: ${({ theme }) => theme.palette.background.blueLightest};
		color: ${({ theme }) => theme.palette.text.darkblack};
	}
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

const AllIconWrapper = styled.div`
	position: absolute;
	top: ${Spacings.SPACING_4B};
	right: ${Spacings.SPACING_8B};
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	cursor: pointer;
	padding: 0.125rem;
	background: ${({ theme }) => theme.palette.background.white};
	color: ${({ theme }) => theme.palette.text.black};

	transition: all 0.15s ease-out;

	& > svg {
		font-size: ${Text.M_LARGE};
	}

	&:hover {
		background: ${({ theme }) => theme.palette.background.blueLightest};
		color: ${({ theme }) => theme.palette.text.darkblack};
	}
`

const CreateItemButton = styled.button`
	width: 100%;
	max-width: 100%;
	min-height: 2.25rem;
	border: none;
	background: #1967d2;
	color: white;
	cursor: pointer;
`

const CheckIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: ${Spacings.SPACING_3B};
	pointer-events: none;

	& > svg {
		font-size: ${Text.X_LARGE};
		fill: ${({ theme }) => theme.palette.text.darkblack};
	}
`
const CrossIconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: ${Spacings.SPACING_1B};
	cursor: pointer;

	& > svg {
		font-size: ${Text.MEDIUM};
		fill: ${({ theme }) => theme.palette.text.darkblack};
	}
`

const SelectedOptionsPillsWrapper = styled.div`
	display: flex;
	margin-bottom: ${({ show }) => (show ? Spacings.SPACING_1B : 0)};
	max-height: 0;
	max-height: ${({ show }) => (show ? Spacings.SPACING_6B : 0)};
	transition: all 0.15s ease-in-out;

	position: absolute;
	top: 0;
	left: 0;

	overflow: scroll;
	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	& {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
`
const OptionPill = styled.div`
	display: flex;
	font-size: ${Text.EXTRA_SMALL};
	min-height: ${Spacings.SPACING_6B};
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.palette.background.pill};
	margin-right: ${Spacings.SPACING_2B};
	border-radius: ${Spacings.SPACING_2B};
	padding: 0 ${Spacings.SPACING_6B} 0 ${Spacings.SPACING_2B};
	position: relative;
	flex-shrink: 0;
`

const MultiSelect = React.memo(({ id, label, data, value, displayKey, onChange, readOnly, disabled, primaryKey, uppercase, ...props }) => {
	const elem = useRef()
	const listRef = useRef()
	const cancelRef = useRef()
	const filterFunctionRef = useRef()
	const wrapperRef = useRef()
	const optionPillsWrapperRef = useRef()
	const updateWidthOfOptionsPillContainerRef = useRef()

	const [focus, setFocus] = useState(props?.autofocus)
	const [inputValue, setInputValue] = useState('')
	const [selectAll, setSelectAll] = useState(false)
	const [options, setOptions] = useState([])

	const showCloseIcon = (focus && inputValue) || props.cancelClick

	props.placeholder = value.length
		? value.length === data.length
			? 'All selected'
			: `${value.length} selected, select more...`
		: props.placeholder + `${props.placeholder ? ', u' : 'U'}se checkbox to select all`

	useEffect(() => {
		filterFunctionRef.current = debounce(filterOptions, 200)
		updateWidthOfOptionsPillContainerRef.current = debounce(updateWidthOfOptionsPillContainer, 200)

		const resizeHandler = () => {
			updateWidthOfOptionsPillContainerRef.current()
		}

		window.addEventListener('resize', resizeHandler)

		return () => {
			window.removeEventListener('resize', resizeHandler)
		}
	}, [])

	useEffect(() => {
		updateWidthOfOptionsPillContainer()
		if (value.length !== data?.length && selectAll) {
			setSelectAll(false)
		}
	}, [value])

	useEffect(() => {
		const pointerDownHandler = (e) => {
			const target = e.target
			if (target && elem.current && listRef.current)
				if (
					target !== elem.current &&
					target !== listRef.current &&
					!listRef.current.contains(target) &&
					(!cancelRef.current || (cancelRef.current && !cancelRef.current.contains(target)))
				) {
					setFocus(false)
				}
		}

		if (focus) {
			setInputValue('')
			setOptions(data)
			window.addEventListener('pointerdown', pointerDownHandler)
		} else if (value) {
			setInputValue(value[displayKey] ?? '')
		}

		return () => {
			window.removeEventListener('pointerdown', pointerDownHandler)
		}
	}, [focus])

	useEffect(() => {
		setOptions(data)
	}, [data])

	useEffect(() => {
		if (focus && filterFunctionRef.current) {
			filterFunctionRef.current(data, inputValue)
		}
	}, [inputValue])

	const updateWidthOfOptionsPillContainer = () => {
		if (optionPillsWrapperRef.current && wrapperRef.current) {
			const { width } = wrapperRef.current.getBoundingClientRect()
			optionPillsWrapperRef.current.style['width'] = width + 'px'
		}
	}

	const filterOptions = (data, searchString) => {
		if (searchString) {
			const filteredOptions = []
			data.forEach((option) => {
				if (option[displayKey].toLowerCase().replaceAll(' ', '').includes(searchString.toLowerCase().replaceAll(' ', ''))) {
					filteredOptions.push(option)
				}
			})
			setOptions(filteredOptions)
		} else {
			setOptions(data)
		}
	}

	const changeHandler = (e) => {
		let value = e.target.value ?? ''
		if (uppercase) {
			value = value.toUpperCase()
		}
		setInputValue(value)
	}

	const focusHandler = (e) => {
		setFocus(true)
	}

	const optionClickHandler = (e) => {
		const target = e.target
		let optionIndex = target.dataset.index

		if (optionIndex !== undefined) {
			optionIndex = isNaN(+optionIndex) ? optionIndex : +optionIndex
			const selectedOption = options.find((option) => option[primaryKey] === optionIndex)

			const thisOptionIndexInValue = (value ?? []).findIndex((_item) => _item[primaryKey] === optionIndex)
			if (thisOptionIndexInValue === -1) {
				// add this item to selected items
				onChange([...value, selectedOption])
			} else {
				// remove this item from selected array
				onChange(value.filter((_item) => _item[primaryKey] !== optionIndex))
			}

			// setFocus(false)
		}
	}

	const selectAllHandler = () => {
		setSelectAll((_s) => {
			onChange(_s ? [] : options)
			return !_s
		})
	}

	const cancelClickHandler = () => {
		setInputValue('')
		elem.current.focus()
		props.cancelClick && props.cancelClick()
		if (!showCloseIcon && focus) {
			setFocus(false)
		}
	}

	const isSelected = (id) => {
		return !!(value ?? []).find((_item) => _item[primaryKey] === id)
	}

	const renderOptions = () => {
		return (
			<OptionsList show={focus} onClick={optionClickHandler} ref={(_ref) => (listRef.current = _ref)}>
				{options?.length
					? options.map((item) => {
							const _i = item[primaryKey]
							if (_i === undefined || value === undefined) {
								return null
							}
							const selected = isSelected(_i)
							return (
								<Option data-index={_i} key={`select-${id}-option-${_i}`} selected={selected}>
									<span>{item[displayKey]}</span>
									{selected && (
										<CheckIconWrapper>
											<Check />
										</CheckIconWrapper>
									)}
								</Option>
							)
					  })
					: renderCreateNewItem()}
			</OptionsList>
		)
	}
	const onCreateItemClickHandler = () => {
		setFocus(false)
		const optionValue = {
			primaryKey: primaryKey ?? null,
			displayKey: displayKey ?? null,
			// product_name: inputValue,
			// product_id: inputValue,
			is_new_item: true,
			[primaryKey]: inputValue,
			[displayKey]: inputValue,
		}
		setInputValue(optionValue)
		onChange([...value, optionValue])
	}

	const onKeyDownhandler = (e) => {
		if (e.keyCode === 13) {
			setFocus(false)
			const optionValue = {
				primaryKey: primaryKey ?? null,
				displayKey: displayKey ?? null,
				// product_name: inputValue,
				// product_id: inputValue,
				is_new_item: true,
				[primaryKey]: inputValue,
				[displayKey]: inputValue,
			}
			setInputValue('')
			onChange([...value, optionValue])
		}
	}

	const renderCreateNewItem = () => {
		return (
			<>
				<NoOption>
					No match Found.
					{!!inputValue && props?.createNewOption}
				</NoOption>
				{!!inputValue && props?.createNewOption && (
					<Option createItemOption={true}>
						<CreateItemButton onClick={onCreateItemClickHandler}>Click here to select entered product</CreateItemButton>
					</Option>
				)}
			</>
		)
	}

	const renderIcon = () => {
		if (showCloseIcon) {
			return <Close />
		}
		return focus ? <ExpandLess /> : <ExpandMore />
	}

	const unselectOption = (option) => (_) => {
		onChange(value.filter((_item) => _item[primaryKey] !== option[primaryKey]))
	}

	const renderSelectedOptions = () => {
		return (
			<SelectedOptionsPillsWrapper show={!!value.length} ref={(_ref) => (optionPillsWrapperRef.current = _ref)}>
				{value.map((_v, index) => {
					return (
						<OptionPill key={`option_pill_${_v[primaryKey]}${index}`}>
							{_v[displayKey]}
							{!readOnly && !disabled && (
								<CrossIconWrapper onClick={unselectOption(_v)}>
									<Cancel />
								</CrossIconWrapper>
							)}
						</OptionPill>
					)
				})}
			</SelectedOptionsPillsWrapper>
		)
	}

	return (
		<MultiSelecteInputWrapper displayPills={!!value.length}>
			{renderSelectedOptions()}
			<FieldWrapper ref={(_ref) => (wrapperRef.current = _ref)}>
				<InputText show={readOnly} showingPlaceholder={value === undefined || value === null || value === ''}>
					{value ? value[displayKey] : props?.placeholder}
				</InputText>
				<Label readOnly={readOnly} htmlFor={id} focus={focus}>
					{label}
				</Label>
				<SelectStyledInput
					ref={(_ref) => (elem.current = _ref)}
					show={!readOnly}
					disabled={readOnly || disabled}
					id={id}
					value={inputValue}
					onChange={changeHandler}
					onFocus={focusHandler}
					uppercase={uppercase}
					{...props}
					autoComplete='off'
					onKeyDown={(e) => onKeyDownhandler(e)}
					onClick={() => {
						!focus && setFocus(true)
					}}
				/>
				{!readOnly && !disabled && (
					<IconWrapper onClick={cancelClickHandler} ref={(_ref) => (cancelRef.current = _ref)}>
						{renderIcon()}
					</IconWrapper>
				)}
				{!props.small && (
					<IconWrapper right={Spacings.SPACING_8B} disabled={options ? options.length === 0 : false}>
						<Checkbox
							size='1.2'
							value={selectAll}
							checked={selectAll}
							onChange={selectAllHandler}
							title='Select All'
							disabled={(options ? options.length === 0 : false) || disabled}
						/>
					</IconWrapper>
				)}
				{renderOptions()}
			</FieldWrapper>
		</MultiSelecteInputWrapper>
	)
})

export default MultiSelect
